import "../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWXW/bIBR9z69AlSq1Uomc5mMZ1fY/pmkPBK7tu2KwADfppv73yWC7OHHaZaq0t3AN95x7ONybuaxchjYjv2eESHS14s+M5AoODzNChFHGMvLE7Q2lPM9RA/Vw8LS2WHH7TMOG23ZrCViUnpH1qg5H2xTUlRb1IyNZTKY9R82I8xaFb0N7lL5kZJFl1+2SKyw0RQ+VC7vAi7KNe8u1Q49GM7Lj4rGwptEygpNsfu/uiKm5QP8cVu0R8wQ2V2bPSIlSgg4EGuvaciTkvFEdAaWoKLkugPU5HmYvs3knCyvbREGcY+AjWcLGQY/XDN8l95wKheKR7xR88baBHyFhT6c2qD3Y5NAifO8VXa2jojtjJVhqucTGtaLF8MmljVQUEHOn/M8zpzkqBfI2wrX3x2Wr4Wh/BbrpvoSNFT/Q7iKXm45UhbqP3cfYUNx9KK42/YVaUNzjE8RUtkBNFeSeEbqNuXodepeYxivUwIg2OpyquZSoi+7YNqJF3aXlRZHo3nNgO8iNhXgPRnvQnpGrq4cRM75zRjU+YHhTM7KOBIIdc2MrFn8q7uHbDV1n10GPSCNL/L0aF9ItU1fHT2e8/I7zasUFlEbJxH8nbrmPmF3qgV7i/kjhLn0F72gYjJMqOdzUMdribLYkiLo4QghJXynP1/+WZorisqPYm3SKcP+EyVfCcrTOU1GiktG/I88tNiOLhxOKTx6wEX87OrAMW/pHtLrsYRe87rvBkHA13c/PZDjt1bnRnjr8BUdeC/GSLtMWt57GCkklWhDR4sKoptInLCK259YnT5mRrNN01F4EV+KmbQOEks2qPtz2OOHCCNmX6IG6movQHfaW1224cWCpAwXC901jIL+JHpuYFm9K4KrbYc++s9Qmy94gkdAME/QVEpTC2qF7lcaBypMBOJD9dJbs2UHtQBgtx6P6zcoO7i9V/ecqtpd5830nTpePwui06BOP90//qDvH9cD288ex7aS96K6SB3E0DvtRO/oPNdDm/5f2REdpI613Ug8dVzfQ311E/2fjPOYtfjfJAxhoOUVlwBAfhxF7Gw2deDP9//flDyL8X9NqCwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = 'dms0irb';
export var dateCell = 'dms0ira';
export var dndCell = 'dms0ir2';
export var dragPageItemOverlay = 'dms0ir1';
export var iconCell = 'dms0ir8';
export var operationsCell = 'dms0irc';
export var root = 'dms0ir0';
export var selectionCell = 'dms0ir4';
export var tagsCell = 'dms0ir9';
export var titleCell = 'dms0ir5';
export var titleCellMain = 'dms0ir6';
export var titleCellPreview = 'dms0ir7';
export var titleIconsWrapper = 'dms0ir3';