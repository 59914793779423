import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71W227cIBB9z1egSJGyUom8126J2v+oqqpiYWxPg8ECnN20yr9XBjuLvd5c2qhv9nguZ84cBt/8mFersjFFRn5fECLR1Yo/MJIrONxeECKMMpaRe26vKeV5jhqoh4OntcWK2wcaHGatawlYlJ6R9aoOoW0K6kqL+o6RrLXsUfqSkXmWXbWvXGGhKXqoHCPOW/CijDW156iDDYVvTd5y7dCj0YzsuLgrrGm0jMVJdrNwH4ipuUD/EN7aEHMPNldmz0iJUoIOmRvr2nYk5LxR/vbi8eLmiQBWtiGBhnGJEQHB8anzNMc3yT2nQqG44zsFn71t4HtI2ZeuDWoPdhA2Dx49f6t15G9nrARLLZfYOEa20XoyoQGLAmLytIXz4GmOSoGcxWrtsLhsCRv4V6Cb7ktwrPiBdoNcbrIIqkLd2xbRlnS3CN3Vpp+fBcU93kNMZgvUVEHuGaFdiz0RvU5M4xVqYEQbHaJqLiXqogvbxnqReml5USTUH1GwHeTGQhyG0R60Z+Ty8naAje+cUY0PVbypGVlHCEF/ubEVi4+Ke/h6TdfZVeAkAkk1vhq20r2mMo6fzoj3BQHWigsojZKJDE8Es4g1u9QB3itYCvJIuXqaxjjf/Gy+xIi6OKkR0h5h3az/NtEUzGUHs5fjFOjjeSVfCMvROk9FiUpGpQ7UNd+M5BxiFJ8MsRHDdhSyDE79oVm97SD/bJzHvF20nWaPnwpet6dkVG01vcnPpD/d0rnRnjr8BSPRBXtJl8OVt56uFtJKtCCi2oVRTaVPcMTqnlufnGtGso72wbYRXInrdicQSjar+jDr64SpErIv0QN1NRdhVewtr1tz48BSBwqE7zdIAn8TxThxVzxLg6tmTz77TnmbLHsGRgI03J/HkqAU1g7dkRwHKk9uxATux7Nwz17UDoTRcnhVP9vbwb2S2X/oY/s2jb6syGkCUBidtj3SegLo0/sB6vh700AS5Y8uwf6KHfw7JcD5/wN++ss3sTxaSyuQVCin3T1/KHdv6ulkQQYMoOULIxfvVyVuNxr28Wb67/fxD7u54kZqCwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1m4huogb';
export var dateCell = '_1m4huoga';
export var dndCell = '_1m4huog2';
export var dragPageItemOverlay = '_1m4huog1';
export var iconCell = '_1m4huog8';
export var operationsCell = '_1m4huogc';
export var root = '_1m4huog0';
export var selectionCell = '_1m4huog4';
export var tagsCell = '_1m4huog9';
export var titleCell = '_1m4huog5';
export var titleCellMain = '_1m4huog6';
export var titleCellPreview = '_1m4huog7';
export var titleIconsWrapper = '_1m4huog3';